import React from "react";

export type FeatureCardProps = {
  icon: React.ReactNode;
  title: string;
  description: string;
  iconBackgroundColor?: string;
};

const FeatureCard: React.FC<FeatureCardProps> = ({
  icon,
  title,
  description,
  iconBackgroundColor,
}) => (
  <div className="flex gap-0 justify-center mt-8 max-md:flex-wrap">
    <div className="flex flex-col flex-1 justify-center py-16 max-md:py-10 p-16 bg-white rounded-xl border-l-[12px] border-l-blue-600 max-md:px-5 max-md:max-w-full">
      <div className="flex gap-5 justify-between max-md:flex-wrap">
        <div
          className={`flex flex-shrink-0 justify-center items-center px-6 py-8 my-auto w-20 h-20 rounded-[100px] max-md:px-5
          ${iconBackgroundColor ? iconBackgroundColor : "bg-violet-50"}`}
        >
          {icon}
        </div>
        <div className="flex flex-grow flex-col justify-center max-md:max-w-full">
          <div className="text-3xl leading-9 text-brand-primary-2 max-md:max-w-full">
            {title}
          </div>
          <div className="mt-6 text-lg leading-7 text-gray-400 max-md:max-w-full">
            {description}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default FeatureCard;