import React from "react";
import Button from "./Button";
import FeatureCard, { FeatureCardProps } from "./FeatureCard";

export default function FeaturesList({
  header,
  features,
  iconBackgroundColor,
}: {
  header: React.ReactNode;
  features: Array<FeatureCardProps>;
    iconBackgroundColor?: string;
}) {
  return (
    <section className="flex flex-col justify-center items-center px-16 py-20 w-full bg-violet-50 max-md:px-5 max-md:max-w-full">
      <div className="flex flex-col w-full max-md:max-w-full mb-20 max-w-[1170px]">
        {header}
        {features.map((feature, index) => (
          <FeatureCard
            key={index}
            icon={feature.icon}
            title={feature.title}
            description={feature.description}
            iconBackgroundColor={iconBackgroundColor}
          />
        ))}
      </div>
      <a href="/request-demo">
        <Button text="Request a Demo" variant="primary" />
      </a>
    </section>
  );
}