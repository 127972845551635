import { caseStudies, CaseStudyProps } from "./data/caseStudies";
import Image from "next/image";


const CaseStudy: React.FC<CaseStudyProps> = ({
  href,
  imageSrc,
  logoSrc,
  description,
  stats,
  features,
}) => (
  <article className="box-border flex relative flex-col shrink-0">
    <div className="flex overflow-hidden relative flex-col justify-end p-8 text-gray-700 rounded-2xl max-md:px-5 max-md:max-w-full max-w-[900px] min-h-[600px]">
      <Image
        loading="lazy"
        src={imageSrc}
        alt=""
        className="object-cover absolute inset-0 size-full"
        width="600"
        height="900"
      />
      <a href={href} className="flex relative flex-col p-10 justify-end rounded-2xl backdrop-blur-[10px] bg-white bg-opacity-90 max-md:px-5 max-md:mt-10 max-md:max-w-full">
        <img
          loading="lazy"
          src={logoSrc}
          alt="Company logo"
          className="h-[36px] object-scale-down flex self-start"
        />
        <p className="mt-4 text-xs leading-5 max-md:max-w-full">
          {description}
        </p>
        <div className="flex gap-5 pb-5 mt-6 max-md:flex-wrap">
          {stats.map((stat, index) => (
            <div key={index} className="flex flex-col flex-1">
              <h3 className="text-base font-bold leading-6">{stat.title}</h3>
              <p className="mt-4 text-xs leading-5">{stat.description}</p>
            </div>
          ))}
          {features && (
            <div className="flex flex-col flex-1">
              <h3 className="text-base font-bold leading-6">Features Used</h3>
              <ul className="mt-4 text-xs leading-5">
                {features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="flex gap-2 pr-20 mt-6 text-base font-bold leading-6 text-blue-600 max-md:flex-wrap max-md:pr-5">
          <div className="my-auto">
            Read Case Study
          </div>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
              <rect width="24" height="24" fill="#434956" />
            </mask>
            <g>
              <path
                d="M14 18L12.6 16.55L16.15 13H4V11H16.15L12.6 7.45L14 6L20 12L14 18Z"
                fill="#226CFF"
              />
            </g>
          </svg>
        </div>
      </a>
    </div>
  </article>
);

export default function ScrollingCaseStudies({
  className
}: {
    className?: string;
}) {
  return (
    <section className={`flex justify-center items-center px-16 py-20 w-full max-md:px-5 max-md:max-w-full ${className}`}>
      <div className="flex flex-col mt-12 mb-6 w-full max-w-[1140px] max-md:mt-10 max-md:max-w-full">
        <h1 className="text-4xl font-bold text-center leading-[52px] max-md:max-w-full">
          <span className="text-brand-primary-2">Why our customers love us</span>
        </h1>
        <div className="flex overflow-auto overflow-x-auto flex-row gap-5 justify-between mt-20 max-md:mt-10">
          {caseStudies.map((study, index) => (
            <CaseStudy key={index} {...study} />
          ))}
        </div>
      </div>
    </section>
  );
};
