
export default function TrustedByLogos() { 
  return (
    <section className="flex flex-col items-center p-20 w-full bg-white max-md:px-5 max-md:max-w-full">
      <div className="justify-center px-4 py-1 text-xs font-semibold tracking-normal leading-5 text-center text-gray-400 border border-gray-400 border-solid rounded-[100px]">
        Trusted by Industry Leaders
      </div>
      <div className="flex flex-wrap gap-8 gap-y-14 justify-between content-center items-center mt-10 w-full max-w-[1170px] max-md:max-w-full">
        {[
          {
            src: `/logos/Microsoft.jpg`,
            alt: "Microsoft",
            className:
              "shrink-0 self-stretch my-auto max-w-full aspect-[4.17] w-[126px]",
          },
          {
            src: `/logos/WBDSports.jpg`,
            alt: "WBDSports",
            className:
              "shrink-0 self-stretch my-auto max-w-full aspect-[4.55] w-[147px]",
          },
          {
            src: `/logos/XGames.jpg`,
            alt: "XGames",
            className:
              "shrink-0 self-stretch my-auto max-w-full aspect-[3.45] w-[105px]",
          },
          {
            src: `/logos/ArnoldSports.jpg`,
            alt: "ArnoldSports",
            className: "shrink-0 self-start max-w-full aspect-[3.23] w-[129px]",
          },
          {
            src: `/logos/SEGA.jpg`,
            alt: "SEGA",
            className: "shrink-0 self-stretch my-auto aspect-[3.03] w-[78px]",
          },
          {
            src: `/logos/AVP.jpg`,
            alt: "AVP",
            className: "shrink-0 self-stretch my-auto aspect-[2.38] w-[72px]",
          },
          {
            src: `/logos/CueSports.jpg`,
            alt: "CueSports",
            className: "shrink-0 self-stretch aspect-[1.39] w-[58px]",
          },
          {
            src: `/logos/Symphony.jpg`,
            alt: "Symphony",
            className:
              "shrink-0 self-stretch my-auto max-w-full aspect-[3.7] w-[102px]",
          },
          {
            src: `/logos/MediaNug.jpg`,
            alt: "MediaNug",
            className:
              "shrink-0 self-stretch my-auto max-w-full aspect-[4.76] w-[106px]",
          },
          {
            src: `/logos/App.jpg`,
            alt: "App",
            className: "shrink-0 self-stretch my-auto aspect-[4.17] w-[92px]",
          },
          {
            src: `/logos/Krafton.jpg`,
            alt: "Krafton",
            className:
              "shrink-0 self-stretch my-auto max-w-full aspect-[5.88] w-[115px]",
          },
          {
            src: `/logos/LiquidDogs.jpg`,
            alt: "LiquidDogs",
            className: "shrink-0 self-stretch aspect-[0.95] w-[37px]",
          },
          {
            src: `/logos/BlueMammoth.jpg`,
            alt: "BlueMammoth",
            className: "shrink-0 self-stretch my-auto aspect-[2.33] w-[79px]",
          },
          {
            src: `/logos/Evo.jpg`,
            alt: "Evo",
            className: "shrink-0 self-stretch my-auto w-9 aspect-[1.12]",
          },
          {
            src: `/logos/Ubisoft.jpg`,
            alt: "Ubisoft",
            className: "shrink-0 self-stretch my-auto aspect-[3.33] w-[92px]",
          },
        ].map((logo, index) => (
          <img
            key={index}
            loading="lazy"
            src={logo.src}
            alt={logo.alt}
            className={logo.className}
          />
        ))}
      </div>
    </section>
  );
}